
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { countryNoComList } from "@/utils";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerPriceList, exportBloggerPriceList, getBloggerPriceLogList } from "@/api/request/blogger";
import DTCls from "@/utils/datetime";

//组件
@Component({
  name: "BloggerPriceSet",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //列表
  private total: number = 0; //总数
  private countryList: any[] = []; //归属国家列表
  private listLoading: boolean = false; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    blogger_id_str: "",

    //临时数据
    level: 0,
    country: "",
    blogger_id: 0,
    blogger_nickname: "",
  };

  //等级列表
  private levelList: any[] = [
    { id: 0, name: "全部博主" },
    { id: 1, name: "见习博主" },
    { id: 2, name: "新晋博主" },
    { id: 3, name: "优秀博主" },
    { id: 4, name: "金牌博主" },
    { id: 5, name: "铂金博主" },
    { id: 6, name: "钻石博主" },
    { id: 7, name: "皇冠博主" },
    { id: 8, name: "首席博主" },
  ];

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
    };
  }

  //创建调用
  created() {
    //数据赋值
    this.countryList = countryNoComList;

    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await getBloggerPriceList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理导出
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await exportBloggerPriceList(this.listQuery);

    //保存数据
    saveAs(data, "博主价格设置列表");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理日志
  private handleLog(row: any): void {
    //变动记录
    this.logQuery = {
      //页码
      page: 1,
      page_size: 20,

      //临时数据
      time: [],

      //请求数据
      end_time: "", //结束时间
      start_time: "", //开始时间
      blogger_id: row.blogger_id, //博主ID
    };

    //显示界面
    this.dialogVisible = true;

    //获取数据
    this.getLogList();
  }

  //获取归属国家名称
  private getCountryName(country: string): string {
    //没有国家属性
    if (country == "") {
      return "-";
    }

    //判断国家
    for (var i: number = 0; i < this.countryList.length; i++) {
      if (country == this.countryList[i].country) {
        return this.countryList[i].name;
      }
    }

    //反馈空
    return "";
  }

  //-------------------------------- 变动记录 ------------------------------
  //定义变量
  private logList: any[] = [];
  private logTotal: number = 0;
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //请求数据
  private logQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    time: [],

    //请求数据
    end_time: "", //结束时间
    start_time: "", //开始时间
    blogger_id: 0, //博主ID
  };

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogVisible = false;
  }

  //获取数据
  private async getLogList() {
    //显示等待
    this.dialogLoading = true;

    //时间赋值
    if (this.logQuery.time && this.logQuery.time.length == 2) {
      this.logQuery.end_time = this.logQuery.time[1] + " 23:59:59";
      this.logQuery.begin_time = this.logQuery.time[0] + " 00:00:00";
    } else {
      this.logQuery.end_time = "";
      this.logQuery.begin_time = "";
    }

    //获取数据
    const { data } = await getBloggerPriceLogList(this.logQuery);

    //数据赋值
    this.logList = data.list;
    this.logTotal = data.total;

    //隐藏等待
    setTimeout(() => {
      this.dialogLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleLogSearch(): void {
    //数据赋值
    this.logQuery.page = 1;

    //获取列表
    this.getLogList();
  }
}
